<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->
            <InvoiceHeader
                :contentType="'mailInvoice'"
                :invoiceNumber="invoice.number"
                :invoiceStatus="invoice.status"
                :submit="onUpdate"
                :id="state.invoiceId"
                :invoiceForm="invoiceFormRef"
                :invoiceTemplate="state.invoice_template"
                :invoice="invoice"
                @on-pay-invoice="payInvoice"
            />

            <CreateEntity :closeModal="() => toggleCreateEntity()" />

            <template v-if="isLoading"><Spinner /></template>

            <div class="w-90 center pv4-l pv3 mini-spacing">
                <div class="w-90 center">
                    <select name="template" v-model="state.invoice_template">
                        <option value="default">Default Template</option>
                        <option value="letterhead">Letterhead Template</option>
                    </select>
                </div>
                <div class="flex flex-wrap justify-between w-90 center">
                    <div class="w-60-l w-100 mb4 mb0-l">
                        <div v-if="state.invoice_template == 'default'" ref="invoiceFormRef">
                            <div class="box-border pv4" style="background: #FBFCFE;">
                                <div class="w-90 center pa3-l pa2 box-border" style="background: white;">
                                    <div class="flex flex-wrap justify-between pv3">
                                        <div class="font-w1 pb3 pb0-l">Invoice No {{ invoice.number }}</div>
                                        <div class="flex">
                                            <div>Date issued: {{ formatDate(invoice.dateIssued) }}</div>
                                            <div class="pl3">Date due: {{ formatDate(invoice.dateDue) }}</div>
                                            <div v-if="invoice?.last_payment_date">
                                                Last payment date: {{ formatDate(invoice.last_payment_date) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pa3" style="background: #F7F9FD;">
                                        <div class="flex items-center justify-between pb2">
                                            <div>
                                                <div class="f3 b pb2">
                                                    Invoice
                                                    <invoice-status :invoice="invoice" />
                                                </div>
                                                <div>{{ invoice.description }}</div>
                                            </div>
                                            <div class="db-l dn">
                                                <img src="imgs/BA-large.svg" alt="" />
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap justify-between bill-section">
                                            <div class="pv3">
                                                <div class="b pb2" style="color: #132C8C;">Bill to</div>
                                                <div>
                                                    <div class="pb2 b">{{ invoice.customer?.name }}</div>
                                                    <div class="pb2">{{ invoice.customer?.address }}</div>
                                                    <div class="pb2">{{ invoice.customer?.email }}</div>
                                                    <div class="pb2">{{ invoice.customer?.phone }}</div>
                                                    <div v-if="invoice.business?.tin" class="pb2">
                                                        TIN: {{ invoice.business?.tin }}
                                                    </div>
                                                    <div v-if="invoice.business?.rcNumber" class="pb2">
                                                        RC No: {{ invoice.business?.rcNumber }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pv3 tr-lx tl">
                                                <div class="b pb2" style="color: #132C8C;">Bill from</div>
                                                <div class="flex justify-center items-center">
                                                    <div ref="companyLogoWrapper" v-if="orgImage">
                                                        <img
                                                            ref="companyLogo"
                                                            :src="orgImage"
                                                            alt=""
                                                            style="object-position: center; object-fit: cover; width: 60px; height: 60px;"
                                                            class="rounded-circle"
                                                        />
                                                    </div>
                                                    <div class="org-img-placeholder" v-else>
                                                        <span>{{ orgInitials || userInitials }}</span>
                                                    </div>
                                                    <div>
                                                        <div class="pb2x b">{{ invoice.business?.name }}</div>
                                                        <div class="pb2">{{ invoice.business?.address }}</div>
                                                        <div class="pb2">{{ invoice.business?.email }}</div>
                                                        <div class="pb2">{{ invoice.business?.phone }}</div>
                                                        <div class="pb2">{{ invoice.business?.tin }}</div>
                                                        <div class="pb2">{{ invoice.business?.rcNumber }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pa3-l pa2">
                                        <div class="flex justify-between pv3 box-border-bottom font-w1">
                                            <div class="w-30">Item</div>
                                            <div class="w-60 flex justify-between">
                                                <div class="w-30 tl">Cost</div>
                                                <div class="w-30 tc">Qty/Unit</div>
                                                <div class="w-30 tr">Price</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="item of invoice.items" v-bind:key="item.id" class="pa3-l pa2">
                                        <div class="flex justify-between pb3 box-border-bottom">
                                            <div class="w-30">
                                                <div class="b">{{ item.name }}</div>
                                                <div v-if="item.notes" class="text-muted" style="font-size:0.9em">
                                                    {{ item.notes }}
                                                </div>
                                            </div>
                                            <div class="w-60 flex justify-between">
                                                <div class="w-30 tl">{{ item.price }}</div>
                                                <div class="w-30 tc">{{ item.quantity }} {{item.measurement}}</div>
                                                <div class="w-30 tr">
                                                    <div>
                                                        {{
                                                            formatAmount(
                                                                item.total || item.price * item.quantity,
                                                                invoice.currency || $store.state.Settings?.currency
                                                            )
                                                        }}
                                                    </div>
                                                    <div v-if="item.discount > 0" class="text-muted" style="font-size:0.7em">
                                                        {{ item.discount }}% discount
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Subtotal -->
                                    <div class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1 ">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Subtotal</div>
                                                <div class="w-30  tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.subtotal,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Discount -->
                                    <div v-if="invoice.hasDiscount" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l "></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Discount</div>
                                                <div v-if="invoice.discountType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.discount,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ invoice.discount }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasCommission" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l "></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Commission</div>
                                                <div v-if="invoice.commissionType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.commission,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ invoice.commission }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasShipping" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l "></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Shipping</div>
                                                <div v-if="invoice.shippingType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.shipping,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ invoice.shipping }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasTax" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l "></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Tax</div>
                                                <div v-if="invoice.taxType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(invoice.tax, invoice.currency || $store.state.Settings?.currency)
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ invoice.tax }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="invoice.hasOthersCharges" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l "></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">{{ invoice.others }}</div>
                                                <div class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            invoice.othersCharges,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Total</div>
                                                <div class="w-30 tr b " style="color: #132C8C;">
                                                    {{
                                                        formatAmount(
                                                            invoice.totalAmount,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="invoice.status?.includes('part_payment') || invoice.status?.includes('paid')"
                                        class="ph3-l ph2"
                                    >
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                                <div class="w-30 tl">Amount Paid</div>
                                                <div class="w-30 tr" style="color: #132C8C;">
                                                    {{
                                                        formatAmount(
                                                            invoice?.deposit || 0,
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="invoice.status?.includes('part_payment') || invoice.status?.includes('paid')"
                                        class="ph3-l ph2"
                                    >
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                                <div class="w-30 tl text-danger">Outstanding</div>
                                                <div class="w-30 tr text-danger">
                                                    {{
                                                        formatAmount(
                                                            invoice.totalAmount - (invoice?.deposit || 0),
                                                            invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="lh-copy pv3" style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #E3E8EE;">
                                        All amounts are in {{ getCurrencyName(invoice.currency) }}. Please make the payment before
                                        {{ formatDate(invoice.dateDue) }}.
                                    </div>
                                    <div
                                        v-if="invoice.notes"
                                        class="lh-copy pv3"
                                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #E3E8EE;"
                                    >
                                        <div class="font-w1">Notes:</div>
                                        {{ invoice.notes ? invoice.notes : '' }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="invoice?.paymentInfo" class="box-border mv4" style="background: #FBFCFE;">
                                <div class="flex justify-between box-border-bottom pa3">
                                    <div>Payment Info</div>
                                </div>
                                <div
                                    v-for="(data, index) of invoice.paymentInfo"
                                    v-bind:key="index"
                                    class="pa3"
                                    style="border-bottom: 1px solid #E3E8EE;"
                                >
                                    <div class="flex justify-between">
                                        <div class="w-30">{{ data.account }}</div>
                                        <div class="w-50 b">
                                            {{ formatAmount(data.amount, invoice.currency || $store.state.Settings?.currency) }}
                                        </div>
                                        <div class="w-20 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="box-border mv4" style="background: #FBFCFE;">
                                <div class="flex justify-between box-border-bottom pa3">
                                    <div>Selected payment option</div>
                                </div>
                                <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
                                    <div class="flex flex-wrap justify-between items-center pa3 box-border">
                                        <div>
                                            <div class="pb2 b">Bank Transfer</div>
                                            <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Bank Name:</div>
                                                <div class="w-70 b">{{ item.bankName }}</div>
                                            </div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Account Number:</div>
                                                <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                                            </div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Account Name:</div>
                                                <div class="w-70 b">{{ item.bankAccountName }}</div>
                                            </div>
                                        </div>
                                        <div class="pt0-l pt3">
                                            <img src="imgs/bank.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div v-if="invoice?.paystackLink" class="pa3">
                                    <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                                        <div>
                                            <div class="pb2 b">Paystack</div>
                                            <div>Pay through the secure paystack API</div>
                                            <div class="flex flex-wrap justify-between items-center pb2">
                                                <a :href="invoice.paystackLink" target="_blank" class="pr2">{{ invoice.paystackLink }}</a>
                                                <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                                                    <span class="icon">
                                                        <font-awesome-icon icon="fa-solid fa-copy" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="pt0-l pt3">
                                            <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <letter-head-invoice v-else :invoice="invoice" :color="invoice.color" :font="invoice.font" />
                    </div>
                    <div class="w-30-l w-100">
                        <div class="box-border" style="background: #FBFCFE;">
                            <div class="box-border-bottom pa3">
                                <div>Send this invoice to this contact</div>
                            </div>
                            <div>
                                <div class="flex justify-between ph3 pt3">
                                    <div>Entity</div>
                                    <!-- <div style="color: #132C8C;">Change</div> -->
                                </div>
                                <div class="pa3">
                                    <div class="box-border pa2 flex items-center">
                                        <img src="imgs/Avatar.png" alt="" />
                                        <div class="pl2">{{ invoice.customer?.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="pa3">
                                <div class="flex justify-between pt3" style="border-top: 1px solid #e3e8ee;">
                                    <div>Other recipients:</div>
                                    <a class="b"  style="color: #132C8C; cursor: pointer" @click.prevent="toggleCreateEntity(true)">+ Add Recipients</a>
                                </div>
                            </div> -->
                        </div>

                        <div class="box-border mt4" style="background: #FBFCFE;">
                            <div class="box-border-bottom pa3">
                                <div>Message to contact</div>
                            </div>
                            <div class="flex flex-column pa3">
                                <label class="pb3" for="">Subject</label>
                                <input
                                    type="text"
                                    class="
                                pa2 box-border"
                                    name=""
                                    placeholder="John ogaga invoice #001 from Business A"
                                    id=""
                                />
                            </div>
                            <div class="flex flex-column pa3">
                                <label class="pb3" for="">Message</label>
                                <textarea name="" id="" cols="20" rows="10" class="box-border pa2"> Enter Message here</textarea>
                            </div>
                        </div>

                        <div v-if="invoice?.status !== 'draft'" class="box-border mt4" style="background: #FBFCFE;">
                            <div class="flex pa3 justify-between items-center box-border-bottom">
                                <div class="w-40 b">Shareable Link</div>
                                <div class="w-20 flex justify-between items-center b tr">
                                    <!-- <span @click.prevent="copyShareLink(state.shareUrl)" style="cursor:pointer">
                                    Copy <img src="../../assets/imgs/copy.svg" class="tr" alt="" />
                                </span> -->
                                    <a
                                        :href="'whatsapp://send?text=' + state.shareUrl"
                                        data-action="share/whatsapp/share"
                                        class="share-btn-mobile"
                                    >
                                        Share
                                    </a>
                                    <a href="" class="share-btn-desktop" @click.prevent="copyShareLink(state.shareUrl)">
                                        Copy
                                    </a>
                                </div>
                            </div>
                            <div class="pa3">
                                <div class="share-link-container box-border pa3">
                                    {{ state.shareUrl }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, watch, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { copyText } from 'vue3-clipboard'
import { htmlToBuffer } from '@/utils/lib'
import { formatAmount, formatQuantity, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import Pagination from '@/components/Pagination'
import CreateEntity from '@/components/CreateEntity'

import Spinner from '@/components/Spinner'
import TableEmptyState from '@/components/TableEmptyState'
import LetterHeadInvoice from '../../components/LetterHeadInvoice.vue'
// import Filter from '@/components/Filter'

export default {
    name: 'InvoiceEmail',
    components: {
        AppWrapper,
        InvoiceHeader,
        InvoiceStatus,
        Pagination,
        Spinner,
        TableEmptyState,
        LetterHeadInvoice,
        CreateEntity
    },

    setup() {
        const router = useRouter()
        const store = useStore()
        // const productPages = computed(() => store?.state.Inventory.productPages)

        const isLoading = computed(() => store?.state.Invoice.isLoading)
        const invoice = computed(() => store?.state.Invoice.invoice)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const route = useRoute()

        const initialState = {
            isLoading: true,
            invoiceId: route.params.id,
            shareUrl: '',
            invoice_template: 'letterhead'
        }
        const invoiceFormRef = ref(null)
        const state = reactive(initialState)

        const copyShareLink = url => {
            copyText(url, undefined, (error, event) => {
                if (error) {
                    store.dispatch('Alert/setAlert', {
                        message: `Could not copy receipt link`,
                        status: false
                    })
                    console.log(error)
                } else {
                    store.dispatch('Alert/setAlert', {
                        message: `Invoice link copied`,
                        status: true
                    })
                    // console.log(event)
                }
            })
        }

        const doCopy = () => {
            copyText(invoice.value.paystackLink, undefined, (error, event) => {
                if (!error) {
                    let copyText = document.querySelector('.copy-text-wrapper');
                    copyText.classList.add('active');
                    setTimeout(function() {
                        copyText.classList.remove('active')
                    }, 2500)
                }
            })
        }

        // watch for changes for invoice
        watch(invoice, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                // console.log('invoice changed', newValue)
            }
        })

        const toggleCreateEntity = payload => {
            //    console.log('toggleCreateEntity', payload);
            store.dispatch('Entity/toggleCreateEntity', payload)
            //if (payload && invoice.value.customer.name) store.dispatch('Entity/setEntityName', state.entity)
        }

        const sendpdf = async () => {
            const file = await htmlToBuffer(invoiceFormRef?.value)
            const formData = new FormData()
            formData.append('imageFile', file)
            formData.append('id', route.params.id)
            // console.log('PDF content', file)
            store.dispatch('Invoice/sendInvoice', formData)
        }

        const duplicateInvoice = invoice => {
            const { _id, reference, number, status, ...data } = invoice
            const lastInvoice = state.lastInvoiceNumber + 1
            const shortName = invoice.business.name.split(' ')
            const firstLetter = shortName[0].charAt(0) + shortName[1].charAt(0)
            // pad with four zeros
            const paddedNumber = ('0000' + lastInvoice).slice(-4)
            const computedInvoiceNumber = firstLetter.toUpperCase() + 'D' + paddedNumber

            let invoicePayload = {
                ...data,
                status: 'draft',
                number: computedInvoiceNumber,
                reference: new ShortUniqueId({ length: 6 }).randomUUID()
            }
            console.log('duplicate invoice payload', lastInvoice, invoicePayload)
            store.dispatch('Invoice/saveInvoice', invoicePayload)
        }

        const payInvoice = invoice => {
            invoice.status = ['paid']
            invoice.sendMail = true
            invoice.mailSent = false
            store.dispatch('Invoice/updateInvoice', invoice)
        }

        onMounted(() => {
            state.shareUrl = `${process.env.VUE_APP_BASE_URL}/invoice/${state.invoiceId}/view`
            store.dispatch('Invoice/getInvoice', route.params.id).then(invoice => {
                if (invoice?.sendMail && !invoice?.mailSent) sendpdf()
            })
        })

        return {
            isLoading,
            invoice,
            formatAmount,
            formatQuantity,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
            state,
            toggleCreateEntity,
            invoiceFormRef,
            orgInitials,
            orgImage,
            copyShareLink,
            payInvoice,
            doCopy,
        }
    }
}
</script>

<style>
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
.invoice-status-badge {
    /* background: #FEEAD7;  */
    /* color: #FB8B24;  */
    border-radius: 20px;
}
@media (max-width: 800px) {
    .flex.bill-section {
        flex-direction: row-reverse;
    }
}
.share-link-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.share-btn-mobile {
    display: none;
}
.share-btn-desktop {
    display: inline;
}
@media screen and (max-width: 1261px) {
    .share-btn-mobile {
        display: inline;
    }
    .share-btn-desktop {
        display: none;
    }
}

.copy-text-wrapper button {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
</style>
